import { DasEndpointType } from '../types/Device';
import { LocatorType } from '../types/Device';

const bleAoaRegex = /^A[A-Z]B/;
const uwbAoaRegex = /^A[A-Z]U/;
const dasairRegex = /^A[A-Z]A/;
const dasgasRegex = /^A[A-Z]G/;
const daswaterLRegex = /^W[A-Z]L/;
const daswatchRegex = /^W[A-Z]/;

export const parseDasId = (
  dasId: string | undefined,
): DasEndpointType | LocatorType | undefined => {
  if (!dasId) return undefined;

  switch (dasId.charAt(0)) {
    case 'L':
      switch (dasId.charAt(2)) {
        case 'S':
        case 'V':
          return 'dasloop_video';
      }
      return 'dasloop';
    case 'P':
      if (/^P[A-Z][A-Z]C/.test(dasId)) {
        return 'dascas';
      } else if (/^P[A-Z][A-Z]G/.test(dasId)) {
        return 'dascas_g';
      }
      return 'dastrack';
    case 'V':
      return 'dastrack_v';
    case 'E':
      switch (dasId.charAt(2)) {
        case 'T':
          return 'dastemp';
        case 'P':
          return 'daspower';
        case 'L':
          return 'daslock';
        default:
          return undefined;
      }
    case 'T':
      return 'das_aoa_tag';
    case 'A':
      if (uwbAoaRegex.test(dasId)) return 'uwb_aoa';
      else if (bleAoaRegex.test(dasId)) return 'ble_aoa';
      else if (dasairRegex.test(dasId)) return 'dasair';
      else if (dasgasRegex.test(dasId)) return 'dasgas';
      return undefined;
    case 'W':
      if (daswaterLRegex.test(dasId)) return 'daswater';
      else if (daswatchRegex.test(dasId)) return 'daswatch';
      return undefined;
    case 'B':
      return 'dasbeacon';
    case 'D':
      return 'dasconcrete';
    case 'I':
      return 'das_ai_box';
    default:
      return undefined;
  }
};

export const mapToDeviceUrl = (
  type: DasEndpointType | LocatorType | 'hock-tag' | undefined,
) => {
  let deviceUrl: string;

  switch (type) {
    case 'dasloop':
      deviceUrl = '/assets/image/svg/device/ic_dasloop.svg';
      break;
    case 'dasloop_video':
      deviceUrl = '/assets/image/svg/device/ic_dasloop_video.svg';
      break;
    case 'dastrack':
      deviceUrl = '/assets/image/svg/device/ic_dastrack.svg';
      break;
    case 'daspower':
      deviceUrl = '/assets/image/svg/device/ic_daspower.svg';
      break;
    case 'dastemp':
      deviceUrl = '/assets/image/svg/device/ic_dastemp.svg';
      break;
    case 'dasair':
      deviceUrl = '/assets/image/svg/device/ic_dasair.svg';
      break;
    case 'das_aoa_tag':
      deviceUrl = '/assets/image/svg/device/ic_dasaoatag.svg';
      break;
    case 'dastrack_v':
      deviceUrl = '/assets/image/svg/device/ic_dastrack-V.svg';
      break;
    case 'daswater':
      deviceUrl = '/assets/image/svg/device/ic_daswater-L.svg';
      break;
    case 'daswatch':
      deviceUrl = '/assets/image/svg/device/ic_daswatch.svg';
      break;
    case 'daslock':
      deviceUrl = '/assets/image/svg/device/ic_daslock.svg';
      break;
    case 'dasbeacon':
      deviceUrl = '/assets/image/svg/device/ic_dasbeacon.svg';
      break;
    case 'ble_aoa':
    case 'uwb_aoa':
      deviceUrl = '/assets/image/svg/device/ic_aoa.svg';
      break;
    case 'dasgas':
      deviceUrl = '/assets/image/svg/device/ic_dasgas.svg';
      break;
    case 'dascas':
      deviceUrl = '/assets/image/svg/device/ic_tower_crane.svg';
      break;
    case 'dascas_g':
      deviceUrl = '/assets/image/svg/device/ic_dascas_g.svg';
      break;
    case 'hock-tag':
      deviceUrl = '/assets/image/svg/device/ic_hock_tag.svg';
      break;
    case 'dasconcrete':
      deviceUrl = '/assets/image/svg/device/ic_dasconcrete.svg';
      break;
    case 'das_ai_box':
      deviceUrl = '/assets/image/svg/device/ic_ai_server.svg';
      break;
    default:
      deviceUrl = '/assets/image/svg/rmr-hardware.svg';
  }

  return deviceUrl;
};

export const delay = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

const dasLoopVideoTypeRegex = /^L.[SV]/;

export const checkIsDasloopVideoCam = (dasId: string) => {
  return dasLoopVideoTypeRegex.test(dasId);
};
